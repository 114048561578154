import { Routes, Route } from 'react-router-dom';
import MemberRoutes from './Auth/MemberRoutes';
import VerifiedMemberRoutes from './Auth/VerifiedMemberRoutes';
import Calculator from './Views/Calculator/Calculator';
import ForgotPassword from './Views/ForgotPassword/ForgotPassword';
import LandingPage from './Views/Landing/Landing';
import LoginPage from './Views/Login/Login';
import MemberDashboard from './Views/MemberDashboard/MemberDashboard';
import MemberKYC from './Views/MemberKYC/MemberKYC';
import NotFound from './Views/NotFound/NotFound';
import Register from './Views/Register/Register';
import TermsAndCondition from './Views/TermsAndCondition/TermsAndCondition';
import RegisterOTP from './Views/RegisterOTP/RegisterOTP';
import FAQ from "./Views/FAQ/FAQ";

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<Register />} />
            <Route path='faqs' element={<FAQ />} />
            <Route path='register/otp' element={<RegisterOTP />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='constitution' element={<TermsAndCondition />} />

            {/* member specific routes */}
            <Route element={<MemberRoutes />}>
                <Route path='member/kyc' element={<MemberKYC />} />
            </Route>

            {/* verified member specific routes */}
            <Route element={<VerifiedMemberRoutes />}>
                <Route path='member/dashboard' element={<MemberDashboard />} />
                <Route path='member/calculator' element={<Calculator />} />
            </Route>

            {/* catch all unhandled routes */}
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default App;
