import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

// importing components
import { Drawer } from 'antd';
import PrimarySidebar from '../PrimarySidebar/PrimarySidebar';

// importing contexts
import { AuthContext } from '../../Contexts/AuthContext';

// importing icons
import { BiMenuAltRight } from "react-icons/bi";

// importing styles
import styles from './LandingNav.module.scss';


const LandingNav = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);
    const { isAuthenticated } = useContext(AuthContext)

    return (
        <div className={styles.landing_nav_container}>
            <div className='container'>
                <div className={styles.landing_nav}>
                    <div className={styles.logo_container}>
                        <Link to='/'>
                            <img className={styles.logo} src='https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png' alt='logo' />
                        </Link>
                    </div>
                    <div className={styles.mobile_hamburger} onClick={() => setDrawerVisible(true)}>
                        <BiMenuAltRight color='var(--color-light)' size={30} />
                    </div>
                    {/* <div className={styles.middle_links}>
                        <Link to='/constitution'> Constitution </Link>
                    </div> */}
                    <div className={styles.right_links}>
                        <Link to='/constitution' className={styles.constitution}>
                            Constitution
                        </Link>
                        <Link to='/faqs' className={styles.constitution}>
                            FAQs
                        </Link>
                        {isAuthenticated ? (
                            <Link to='/member/dashboard' className={styles.login}>
                                Go To Dashboard
                            </Link>
                        ) : (
                            <>
                                <Link to='/register' className={styles.register}>
                                    Register
                                </Link>
                                <Link to='/login' className={styles.login}>
                                    Login
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                {/* show drawer in mobile */}
                <Drawer
                    placement="right"
                    closable={false}
                    onClose={() => setDrawerVisible(false)}
                    open={drawerVisible}
                    key="right"
                    width={270}
                >
                    <PrimarySidebar />
                </Drawer>
            </div>
        </div>
    );
};

export default LandingNav;
