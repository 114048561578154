import { Button, Col, Row, message, Form } from "antd";
import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../Config/axios";
import styles from "./RegisterOTP.module.scss";

const RegisterOTP = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [registrationLoading, setRegistrationLoading] = useState(false);
    const [registerStates, setRegisterStates] = useState({});

    useEffect(() => {
        if (location.state) {
            setRegisterStates(location.state?.registerStates);
        } else {
            navigate("/register");
        }
    }, [location.state, navigate]);

    const RegistrationHandler = async (values) => {
        const payload = {
            ...registerStates,
            user_given_otp: values.user_given_otp,
            contacts: "azima_club_reg",
        };

        try {
            setRegistrationLoading(true);
            await axios.post("https://api.azima.info/api/users/register", payload);
            message.success("Registration successful!");
            setStep(2);
            localStorage.removeItem("registerStates");
        } catch (error) {
            message.error(
                error?.response?.data?.msg
                    ? error.response.data.msg
                    : "A server-side error occurred. Please contact support"
            );
        } finally {
            setRegistrationLoading(false);
        }
    };

    return (
        <div className={styles.register_page_container}>
            <Helmet>
                <title>Submit OTP - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.register_content}>
                    <Row gutter={20}>
                        <Col lg={24}>
                            {step === 1 && (
                                <Row gutter={[16, 16]}>
                                    <Col lg={12}>
                                        <div className={styles.register_left}>
                                            <div className={styles.logo_container}>
                                                <img
                                                    className={styles.logo}
                                                    src="https://i.ibb.co/9WwS3mR/icon-removebg-preview.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <div className={styles.register_form}>
                                                <h5 className={styles.form_title}>Register with Azima Club</h5>
                                                <>
                                                    <div className={styles.form_content_title}>
                                                        To verify your phone number we have sent a 4 digit OTP to{" "}
                                                        {registerStates.phone_number}. Please enter the 4 digit code and
                                                        click on the Verify button below to proceed!
                                                    </div>
                                                    <Form
                                                        onFinish={RegistrationHandler}
                                                        scrollToFirstError
                                                        layout="vertical"
                                                        requiredMark={false}
                                                    >
                                                        <Form.Item
                                                            name="user_given_otp"
                                                            label={null}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please enter 4 digit OTP!",
                                                                },
                                                                {
                                                                    min: 4,
                                                                    message: "OTP is 4 digit long.",
                                                                },
                                                                {
                                                                    max: 4,
                                                                    message: "OTP is 4 digit only.",
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <OTPInput
                                                                autoFocus
                                                                OTPLength={4}
                                                                otpType="number"
                                                                disabled={false}
                                                                secure
                                                                inputClassName={styles.otp}
                                                                inputType={"numeric"}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item>
                                                            <Button
                                                                block
                                                                loading={registrationLoading}
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                Verify & Register
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </>

                                                <div className={styles.bottom_links}>
                                                    <Link to="/login">
                                                        <span>Already have an account?</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12} xs={0}>
                                        <img
                                            src="https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            alt="right_image"
                                            className={styles.register_right_img}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {step === 2 && (
                                <div className={styles.register_content}>
                                    <Row gutter={[16, 16]}>
                                        <Col lg={12}>
                                            <div className={styles.registerSuccess}>
                                                <div className={styles.animatedTick}>
                                                    <svg
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 130.2 130.2"
                                                    >
                                                        <circle
                                                            className={`${styles.path} ${styles.circle}`}
                                                            fill="none"
                                                            stroke="#73AF55"
                                                            strokeWidth="6"
                                                            strokeMiterlimit="10"
                                                            cx="65.1"
                                                            cy="65.1"
                                                            r="62.1"
                                                        />
                                                        <polyline
                                                            className={`${styles.path} ${styles.check}`}
                                                            fill="none"
                                                            stroke="#73AF55"
                                                            strokeWidth="6"
                                                            strokeLinecap="round"
                                                            strokeMiterlimit="10"
                                                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={styles.registerContent}>
                                                    <h2>Registration successful. Please Login to continue.</h2>
                                                </div>
                                                <div className={styles.actionButtons}>
                                                    <Link to="/login">
                                                        <Button size="large" block type="primary">
                                                            Login
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} xs={0}>
                                            <img
                                                src="https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                alt="right_image"
                                                className={styles.register_right_img}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RegisterOTP;
