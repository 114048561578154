import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing styles
import styles from "./LandingHero.module.scss";

// importing styles
import axios from "../../Config/axios";
import { formattedAmount } from "../../utils/utils";

const LandingHero = () => {
    const [globalData, setGlobalData] = useState({});

    useEffect(() => {
        (async () => {
            const res = await axios.get("/fundamentals/global-data");
            setGlobalData(res.data);
        })();
    }, []);

    return (
        <section className={styles.landing_hero_container}>
            <div className={styles.mobile_shade}></div>
            <div className="container">
                <div className={styles.landing_hero_content}>
                    <span className={styles.reg_no}>
                        <span style={{ color: "var(--color-primary)" }}>Reg No:</span> 1681473590436
                    </span>
                    <div className={styles.lading_title}>Azima Club</div>
                    <div className={styles.landing_subtitle}>Invest and earn interest effortlessly.</div>
                    <div className={styles.landing_interest}>
                        Invest any amount from 2,000Ugx using mobile money and watch your money grow at an incredible 18% a year!
                    </div>
                    <div className={styles.landing_interest_2}>Start your journey to financial freedom today.</div>
                    <div className={styles.landing_cta}>
                        <Link to="/register" className={styles.get_started_btn}>
                            Join the Club
                        </Link>
                        <Link to="/login" className={styles.get_started_btn + " " + styles.login_btn}>
                            Login
                        </Link>
                    </div>
                    <div className={styles.stat_section}>
                        <div className={styles.stat}>
                            <div className={styles.stat_title}>Annual Earnings</div>
                            <div className={styles.stat_desc}>
                                <div className={styles.stat_count}>18%</div>
                            </div>
                        </div>

                        <div className={styles.stat}>
                            <div className={styles.stat_title}>Current Members</div>
                            <div className={styles.stat_desc}>
                                <div className={styles.stat_count}>
                                    {globalData.user_count ? formattedAmount(globalData.user_count) : "..."}
                                </div>
                            </div>
                        </div>

                        <div className={styles.stat}>
                            <div className={styles.stat_title}>Get A Loan</div>
                            <div className={styles.stat_desc}>
                                <div className={styles.stat_count}>
                                    <Link to="https://go.azima.info/install" target="_blank" className={styles.download_app_btn}>
                                        Download App
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobile_img_container}>
                        <img className={styles.mobile_img} src="/azima-club-hero.png" alt="mobile" />
                        <img className={styles.ellipse_img} src="https://i.ibb.co/s3015cM/Ellipse-1-2.png" alt="mobile" />
                        <img className={styles.ellipse_img_2} src="https://i.ibb.co/tbd4Q8r/Ellipse-1-1.png" alt="mobile" />
                    </div>
<div className={styles.footer}>
                        <b>Get In Touch</b>
                        <div className={styles.footer__body}>
                            <div className={styles.footer__item}>
                                <span><span style={{fontWeight: 400, color: 'var(--color-primary)'}}>Office:</span> Plot 44, Semawata Road, Ntinda, Kampala, Uganda</span>
                            </div>
                            <div className={styles.footer__item}>
                                <div style={{display: 'flex', gap: '5px', width: '100%'}}>
                                    <span><span
                                        style={{fontWeight: 400, color: 'var(--color-primary)'}}>Cell:</span></span>
                                    <div style={{display: 'flex', flexDirection: 'column', width: 'auto'}}>
                                        <a href="tel:+256 756 711 563">+256 756 711 563</a>
                                        <a href="tel:+256 776 711 563">+256 776 711 563</a>
                                    </div>
                                </div>
                                <span><span style={{fontWeight: 400, color: 'var(--color-primary)'}}>Email:</span> <a
                                    href="mailto:info@azima.club">info@azima.club</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingHero;
