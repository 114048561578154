import { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

// importing styles
import styles from './DashboardSidebar.module.scss';

// importing icons
import { AiFillHome, AiOutlineLogout } from 'react-icons/ai';
import { BsCalculator } from 'react-icons/bs';
import { IoDocumentLockOutline } from 'react-icons/io5';

// importing contexts
import { AuthContext } from '../../Contexts/AuthContext';

const DashboardSidebar = () => {

    let navigate = useNavigate();
    const { setUser, setIsAuthenticated } = useContext(AuthContext);

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.clear();
        navigate("/login", { replace: true });
    };

    return (
        <div className={styles.dashboard_sidebar}>
            <div className={styles.logo_container}>
                <Link to='/'>
                    <img className={styles.logo} src='https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png' alt='logo' />
                </Link>
            </div>
            <div className={styles.sidebar}>
                <NavLink to='/member/dashboard' end>
                    {({ isActive }) => (
                        <div className={isActive ? styles.link_active : styles.link}>
                            <AiFillHome size={20} color='#2e3158' />
                            <div className={styles.label}>Overview</div>
                        </div>
                    )}
                </NavLink>

                <NavLink to='/member/calculator' end>
                    {({ isActive }) => (
                        <div className={isActive ? styles.link_active : styles.link}>
                            <BsCalculator size={20} color='#2e3158' />
                            <div className={styles.label}>Earnings Calculator</div>
                        </div>
                    )}
                </NavLink>

                <NavLink to='/member/kyc' end>
                    {({ isActive }) => (
                        <div className={isActive ? styles.link_active : styles.link}>
                            <IoDocumentLockOutline size={20} color='#2e3158' />
                            <div className={styles.label}>KYC Documents</div>
                        </div>
                    )}
                </NavLink>

                <div className={styles.logout} onClick={handleLogout}>
                    <AiOutlineLogout size={20} color='#2e3158' />
                    <div className={styles.label}>Logout</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardSidebar;
