import { useState } from 'react';

// importing components
import DashboardLayout from '../../Components/DashboardLayout/DashboardLayout';
import { Divider, Input } from 'antd';

// importing styles
import styles from './Calculator.module.scss';

// importing utils
import { calculatePercentage, formattedAmount } from '../../utils/utils';

const Calculator = () => {
    const [amount, setAmount] = useState(2100);
    const [viewAmount, setViewAmount] = useState(2100);


    const amountCalculation = [
        {
            title: 'Your next reward',
            value: calculatePercentage(amount, 0.0484),
        },
        {
            title: 'What you will have in 30 days',
            value: parseInt(amount) + calculatePercentage(amount, 0.0484) * 30,
        },
        {
            title: 'What you will have in 1 year',
            value: parseInt(amount) + calculatePercentage(amount, 0.0484) * 365,
        },
        {
            title: 'What you will have in 2 years',
            value: parseInt(amount) + calculatePercentage(amount, 0.0484) * 365 * 2,
        },
        {
            title: 'What you will have in 5 years',
            value: parseInt(amount) + calculatePercentage(amount, 0.0484) * 365 * 5,
        },
        {
            title: 'What you will have in 10 years',
            value: parseInt(amount) + calculatePercentage(amount, 0.0484) * 365 * 10,
        },
    ];

    return (
        <DashboardLayout title="Calculator">
            <Divider plain>Azima Club Contribution Calculator</Divider>
            <div className={styles.calculator}>
                {
                    (viewAmount > 0 && viewAmount < 2100) &&
                    <span style={{ fontSize: '1.2rem', color: 'var(--color-red)' }}>Minimum value is 2100 UGX </span>
                }
                <div className={styles.item}>
                    <div className={styles.item_title}>Your total contribution</div>
                    <div className={styles.item_input}>
                        <Input
                            value={viewAmount}
                            onChange={(e) => {
                                if (e.target.value >= 2100) {
                                    setAmount(e.target.value)
                                }
                                setViewAmount(e.target.value)
                            }}
                            placeholder='2100'
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            suffix='UGX'
                            type='number'
                            status={(viewAmount > 0 && viewAmount < 2100) ? 'error' : null}
                        />
                    </div>
                </div>
                {amountCalculation.map((amount, index) => (
                    <div className={styles.item} key={index}>
                        <div className={styles.item_title}>{amount.title}</div>
                        <div className={styles.item_value}>{formattedAmount(amount.value)}</div>
                    </div>
                ))}
            </div>
        </DashboardLayout>
    );
};

export default Calculator;
