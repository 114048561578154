import { useContext } from "react";
import { Link } from "react-router-dom";

// importing context
import { AuthContext } from "../../Contexts/AuthContext";

// importing styles
import styles from "./PrimarySidebar.module.scss";

const PrimarySidebar = () => {
    const { isAuthenticated } = useContext(AuthContext);
    return (
        <div className={styles.primary_sidebar}>
            <div className={styles.logo_container}>
                <Link to="/">
                    <img className={styles.logo} src="https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png" alt="logo" />
                </Link>
            </div>
            <div className={styles.primary_links}>
                <Link to='/constitution'> Constitution </Link>
            </div>
            <div className={styles.primary_links}>
                <Link to='/faqs'> FAQs </Link>
            </div>
            <div className={styles.auth_links}>
                {isAuthenticated ? (
                    <Link to="/member/dashboard" className={styles.login}>
                        Go To Dashboard
                    </Link>
                ) : (
                    <>
                        <Link to="/register" className={styles.register}>
                            Register
                        </Link>
                        <Link to="/login" className={styles.login}>
                            Login
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default PrimarySidebar;
