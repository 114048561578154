import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from 'antd';

// importing styles
import styles from "./FAQ.module.scss";

// importing components
import { Col, Row } from "antd";

const FAQ = () => {
    const {Panel} = Collapse;

    const faqs = [
        {
            "question": "What is Azima Investment Club?",
            "answer": "Azima Investment Club is an investment club registered under number 1681473590436 and was setup specifically for us low-income earners, allowing us to invest small amounts and earn interest rates typically reserved for high amount investments like bonds."
        },
        {
            "question": "How do I get started?",
            "answer": "Simply sign up on our website www.azima.club, and once your application is approved, you can make your initial deposit, and watch your investment grow!"
        },
        {
            "question": "How much can I start investing with?",
            "answer": "You can start investing with as little as 2,000Ugx."
        },
        {
            "question": "What is the annual return on my investment?",
            "answer": "You can earn an impressive 18% per annum on your investment."
        },
        {
            "question": "Can I withdraw my money anytime?",
            "answer": "Yes, you have the freedom to withdraw your earnings whenever you want using Mobile Money."
        },
        {
            "question": "Are there any withdrawal fees?",
            "answer": "Yes and No! Telecom fees apply upon withdrawal, the club does not charge you any fees on withdraw."
        },
        {
            "question": "How is Azima Investment Club able to offer such high returns?",
            "answer": "We pool together investments to access opportunities that would typically require larger sums, allowing us to offer high-ticket returns to our investors."
        },
        {
            "question": "Is my money safe with Azima Investment Club?",
            "answer": "Yes, we prioritize the safety of our club members’ funds. We are a fully registered Investment Club with a Constitution which can be accessed at any time here: https://azima.club/constitution."
        },
        {
            "question": "How can I track my investment growth?",
            "answer": "You can monitor your investment growth through our user-friendly online platform/dashboard, which provides real-time updates on your returns."
        },
        {
            "question": "Can I invest more than 2,000Ugx?",
            "answer": "Absolutely! While 2,000Ugx is the minimum, there's no maximum limit. The more you invest, the more you can potentially earn."
        },
        {
            "question": "Are there any hidden fees or charges?",
            "answer": "No, we believe in transparency. All fees, if any, will be clearly communicated to you upfront."
        },
        {
            "question": "How often is interest credited to my account?",
            "answer": "Interest is credited every 24 hours, and you can monitor the growth of your investment on our platform at any time."
        },
        {
            "question": "Can I reinvest my earnings?",
            "answer": "Yes, you can choose to reinvest your earnings to maximize your returns."
        },
        {
            "question": "Who can join Azima Investment Club?",
            "answer": "Anyone interested in growing their savings, especially low-income earners looking for good investment returns. Please note that your account will have to undergo verification and approval before you are able to become a club member. Hence, as much as signups are open, admission into the club is regulated."
        },
        {
            "question": "How do I contact customer support?",
            "answer": "You can reach out to our dedicated customer support team at support@azima.club or call us at +256756711563, or visit our offices on Plot 44, Ssemawata Road, Ntinda."
        }
    ]


    return (
        <div className={styles.register_page_container}>
            <Helmet>
                <title>Register - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.register_content}>
                    <Row gutter={20}>
                        <Col lg={24}>
                            <div className={styles.register_left}>
                                <Link to="/" className={styles.logo_container}>
                                    <img
                                        className={styles.logo}
                                        src="https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png"
                                        alt="logo"
                                    />
                                </Link>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                                    <span style={{textAlign: 'center'}}>Frequently Asked Questions</span>
                                </div>
                                <br/>
                                <Collapse defaultActiveKey={['1']}>
                                    {
                                        faqs.map((faq, index) => {
                                            return <Panel header={faq.question} key={index+1}>
                                                <p>{faq.answer}</p>
                                            </Panel>
                                        })
                                    }
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    );
};

export default FAQ;
