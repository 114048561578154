import { useState, useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// importing styles
import styles from './DashboardLayout.module.scss';

// importing components
import { Row, Col, Avatar, Drawer } from 'antd';
import DashboardSidebar from '../DashboardSidebar/DashboardSidebar';

// importing icons
import { AiFillHome, AiOutlineLogout } from 'react-icons/ai';
import { BsCalculator } from 'react-icons/bs';
import { IoDocumentLockOutline, IoCall } from 'react-icons/io5';
import { BiMenuAltLeft } from "react-icons/bi";
import { MdVerified } from 'react-icons/md';

// importing contexts
import { AuthContext } from '../../Contexts/AuthContext';

const DashboardLayout = ({ title, children }) => {
    let navigate = useNavigate();
    const { user, setUser, setIsAuthenticated } = useContext(AuthContext);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.clear();
        navigate("/login", { replace: true });
    };

    return (
        <div className={styles.dashboard_layout}>
            <Helmet>
                <title>{title} - Azima Club</title>
            </Helmet>
            <div className={styles.layout_top}>
                <Row gutter={20}>
                    <Col lg={0} sm={0} xs={2}>
                        <div className={styles.mobile_hamburger} onClick={() => setDrawerVisible(true)}>
                            <BiMenuAltLeft color='var(--color-dark)' size={30} />
                        </div>
                    </Col>
                    <Col lg={4} sm={4} xs={10}>
                        <Link to='/member/dashboard'>
                            <img className={styles.logo} src='https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png' alt='logo' />
                        </Link>
                    </Col>
                    <Col lg={20} sm={20} xs={12}>
                        <div className={styles.layout_top_right}>
                            <div className={styles.user_data}>
                                <span className={styles.user_name}>
                                    Welcome! {user.user_data.first_name} {user.user_data.last_name}
                                    {
                                        user.user_data.status === 'verified' &&
                                        <MdVerified color='var(--color-primary)' size={15} style={{ marginLeft: '.5rem' }} />
                                    }
                                </span>
                                <div className={styles.user_phone}>
                                    <IoCall size={13} style={{ marginRight: '.5rem', color: 'var(--color-grey-dark-3)' }} />
                                    <span style={{ fontSize: '1.2rem', color: 'var(--color-grey-dark-3)' }}>{user.user_data.phone_number}</span>
                                </div>
                            </div>
                            <div>
                                <Avatar
                                    src={user.user_data?.dp ?? "https://i.ibb.co/rvGKQJg/azima-no-image.png"}
                                    size={45}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* show drawer in mobile */}
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={() => setDrawerVisible(false)}
                    open={drawerVisible}
                    key="left"
                    width={270}
                >
                    <DashboardSidebar />
                </Drawer>
            </div>

            <div className={styles.layout_main}>
                <Row gutter={30}>
                    <Col lg={4} xs={0}>
                        <div className={styles.sidebar}>
                            <NavLink to='/member/dashboard' end>
                                {({ isActive }) => (
                                    <div className={isActive ? styles.link_active : styles.link}>
                                        <AiFillHome size={20} color='#2e3158' />
                                        <div className={styles.label}>Overview</div>
                                    </div>
                                )}
                            </NavLink>

                            <NavLink to='/member/calculator' end>
                                {({ isActive }) => (
                                    <div className={isActive ? styles.link_active : styles.link}>
                                        <BsCalculator size={20} color='#2e3158' />
                                        <div className={styles.label}>Earnings Calculator</div>
                                    </div>
                                )}
                            </NavLink>

                            <NavLink to='/member/kyc' end>
                                {({ isActive }) => (
                                    <div className={isActive ? styles.link_active : styles.link}>
                                        <IoDocumentLockOutline size={20} color='#2e3158' />
                                        <div className={styles.label}>KYC Documents</div>
                                    </div>
                                )}
                            </NavLink>

                            <div className={styles.logout} onClick={handleLogout}>
                                <AiOutlineLogout size={20} color='#2e3158' />
                                <div className={styles.label}>Logout</div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={20} xs={24}>
                        <div className={styles.layout_children}>{children}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DashboardLayout;
