import React, { useContext, useState } from "react";

// importing styles
import styles from "./MemberKYC.module.scss";
import { Button, message, Upload, Row, Col, Divider, Image } from "antd";
import DashboardLayout from "../../Components/DashboardLayout/DashboardLayout";

// importing icons
import { UploadOutlined } from "@ant-design/icons";
import { BsInfoCircle } from "react-icons/bs";

// importing context
import { AuthContext } from "../../Contexts/AuthContext";
import axios from "axios";

const MemberKYC = () => {
  const { user } = useContext(AuthContext);
  const [state, setState] = useState({
    loading: false,
    dp: null,
    nid_front: null,
    nid_back: null,
  });

  const handleUpload = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });

      // payload
      const payload = {
        dp: state.dp,
        nid_front: state.nid_front,
        nid_back: state.nid_back,
      };

      // update kyc document upload endpoint with this url
      await axios.put("https://api.azima.club/api/users/kyc-submit", payload, {
        headers: {
          Authorization: `Token ${localStorage.getItem("device_id")}`,
        },
      });
      message.success("KYC Documents Uploaded Successfully");
      window.location.reload();
    } catch (e) {
      message.error("Something went wrong! Try Again");
    } finally {
      setState({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <DashboardLayout title="KYC Verification">
      {user.user_data.status === "incomplete" ? (
        <Row>
          <Col span={24}>
            <div className={styles.verify_info}>
              <BsInfoCircle size={20} color="var(--color-warning)" />
              <div style={{ color: "var(--color-warning)" }}>
                Please verify your identity by submitting the required KYC documents.
              </div>
            </div>
          </Col>
        </Row>
      ) : user.user_data.status === 'pending verification' ? (
          <Row>
          <Col span={24}>
            <div className={styles.verify_info}>
              <BsInfoCircle size={20} color="var(--color-warning)" />
              <div style={{ color: "var(--color-warning)" }}>
                We are reviewing your KYC Documents. You'll be notified via SMS and Email when your account is verified.
              </div>
            </div>
          </Col>
        </Row>
      ) : null
      }

      <Row gutter={[20, 20]}>
        <Col lg={8} sm={12} xs={24}>
          <ProfilePictureManagement state={state} setState={setState} />
        </Col>
        <Col lg={8} sm={12} xs={24}>
          <NationalIDFrontManagement state={state} setState={setState} />
        </Col>
        <Col lg={8} sm={12} xs={24}>
          <NationalIDBackManagement state={state} setState={setState} />
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 16,
          }}
        >
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={
              !state.dp || !state.nid_front || !state.nid_back || state.loading
            }
            loading={state.loading}
          >
            {state.loading ? "Uploading..." : "Upload Your KYC Documents"}
          </Button>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default MemberKYC;

// sub components
const ProfilePictureManagement = ({ state, setState }) => {
  const { user } = useContext(AuthContext);

  const props = {
    customRequest: ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      // custom API request using Axios, fetch, or any other library
      axios
        .post("https://api.azima.club/api/users/file-upload", formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("device_id")}`,
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          console.log(response.data.secure_url);
          setState({
            ...state,
            dp: response.data.secure_url,
          });
        })
        .catch((error) => {
          onError(error);
        });
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        // message.success(`Profile Picture Uploaded Successfully`);
      } else if (info.file.status === "error") {
        message.error(`Profile Picture Upload Failed.Try Again!`);
      }
    },
    onRemove: () => {
      setState({
        ...state,
        dp: null,
      });
    },
  };

  return (
    <div className={styles.picture_management}>
      <div className={styles.picture_title}> Upload Profile Picture </div>
      <Upload
        {...props}
        disabled={user.user_data.status !== "incomplete"}
        maxCount={1}
      >
        <Button
          disabled={user.user_data.status !== "incomplete"}
          icon={<UploadOutlined />}
        >
          Select File
        </Button>
      </Upload>

      <Divider plain>Current Picture</Divider>
      <Image
        height={200}
        src={user.user_data.dp ?? "https://i.ibb.co/rvGKQJg/azima-no-image.png"}
      />
    </div>
  );
};

const NationalIDFrontManagement = ({ state, setState }) => {
  const { user } = useContext(AuthContext);
  const props = {
    customRequest: ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      // custom API request using Axios, fetch, or any other library
      axios
        .post("https://api.azima.club/api/users/file-upload", formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("device_id")}`,
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          console.log(response.data.secure_url);
          setState({
            ...state,
            nid_front: response.data.secure_url,
          });
        })
        .catch((error) => {
          onError(error);
        });
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        // message.success(`NID Front Uploaded Successfully`);
      } else if (info.file.status === "error") {
        message.error(`NID Front Upload Failed.Try Again!`);
      }
    },
    onRemove: () => {
      setState({
        ...state,
        nid_front: null,
      });
    },
  };

  return (
    <div className={styles.picture_management}>
      <div className={styles.picture_title}> Upload NATIONAL ID FRONT </div>
      <Upload
        {...props}
        disabled={user.user_data.status !== "incomplete"}
        maxCount={1}
      >
        <Button
          disabled={user.user_data.status !== "incomplete"}
          icon={<UploadOutlined />}
        >
          Select File
        </Button>
      </Upload>

      <Divider plain>Current Picture</Divider>
      <Image
        height={200}
        src={
          user.user_data.nid_front ??
          "https://i.ibb.co/rvGKQJg/azima-no-image.png"
        }
      />
    </div>
  );
};

const NationalIDBackManagement = ({ state, setState }) => {
  const { user } = useContext(AuthContext);
  const props = {
    customRequest: ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      // custom API request using Axios, fetch, or any other library
      axios
        .post("https://api.azima.club/api/users/file-upload", formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("device_id")}`,
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          console.log(response.data.secure_url);
          setState({
            ...state,
            nid_back: response.data.secure_url,
          });
        })
        .catch((error) => {
          onError(error);
        });
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        // message.success(`NID Back Uploaded Successfully`);
      } else if (info.file.status === "error") {
        message.error(`NID Back Upload Failed.Try Again!`);
      }
    },
    onRemove: () => {
      setState({
        ...state,
        nid_back: null,
      });
    },
  };

  return (
    <div className={styles.picture_management}>
      <div className={styles.picture_title}> Upload NATIONAL ID Back </div>
      <Upload
        {...props}
        disabled={user.user_data.status !== "incomplete"}
        maxCount={1}
      >
        <Button
          disabled={user.user_data.status !== "incomplete"}
          icon={<UploadOutlined />}
        >
          Select File
        </Button>
      </Upload>

      <Divider plain>Current Picture</Divider>
      <Image
        height={200}
        src={
          user.user_data.nid_back ??
          "https://i.ibb.co/rvGKQJg/azima-no-image.png"
        }
      />
    </div>
  );
};
