import { useRef, useMemo, useState, useEffect } from "react";
import styles from "./TermsCondition.module.scss";

const TermsCondition = ({ setButtonDisabled, page }) => {
    const dissolutionRef = useRef(null);

    //
    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);

        const observer = useMemo(
            () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
            []
        );

        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);

        return isIntersecting;
    }

    const isDissolutionInView = useIsInViewport(dissolutionRef);

    useEffect(() => {
        if (setButtonDisabled) {
            if (isDissolutionInView) {
                setButtonDisabled(false);
            } else {
                setButtonDisabled(true);
            }
        }
    }, [isDissolutionInView, setButtonDisabled]);

    return (
        <div className={styles.terms_modal_body} style={{ maxHeight: page ? "100%" : null }}>
            <p>
                <strong>
                    <u>
                        <span>THE REPUBLIC OF UGANDA</span>
                    </u>
                </strong>
            </p>
            <p>
                <strong>
                    <u>
                        <span>THE CONSTITUTION OF THE AZIMA INVESTMENT CLUB</span>
                    </u>
                </strong>
            </p>

            {/* name */}
            <p>
                {" "}
                <strong>Name</strong>{" "}
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>The club will be called the “Azima Investment Club”.</li>
                    <li>
                        All references to "club" or "investment club" mentioned in this constitution refers to the Azima
                        Investment Club.
                    </li>
                </ol>
            </div>

            {/* office */}

            <p>
                {" "}
                <strong>Office</strong>{" "}
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The main office of the club will be in Plot 44, Ssemawata Road, Ntinda, Kampala, Uganda, with
                        branches in other locations as shall be chosen from time to time.
                    </li>
                    <li>
                        The club's website is{" "}
                        <a href="https://azima.club" target="_blank" rel="noopener noreferrer">
                            https://azima.club
                        </a>{" "}
                        with the email address <a href="mailto:support@azima.club">support@azima.club</a>
                    </li>
                </ol>
            </div>

            {/* objective */}
            <p>
                {" "}
                <strong>Objectives</strong>{" "}
            </p>
            <div>The club's goals are to:</div>
            <div style={{ marginLeft: 30 }}>
                <ol>
                    <li>
                        To mobilize money from its members to be used as an investment fund which shall be managed by
                        the Board of Pay 24 Financial Services Limited.
                    </li>
                    <li>
                        Provide loans to verified users of the Azima Instant Loan mobile app, a product of Pay 24
                        Financial Services Ltd, which allows users to borrow short-term loans using their mobile device
                        as collateral or security.
                    </li>
                    <li>
                        Generate returns on members' individual investments from the interest charged on loans given to
                        Azima Instant Loan mobile app users.
                    </li>
                    <li>
                        Carry out any actions and activities allowed by the club's constitution, Pay 24 Financial
                        Services Limited's regulations, or any applicable laws.
                    </li>
                    <li>Perform any other actions that help achieve the club's goals or support its functions.</li>
                </ol>
            </div>

            {/* operations */}
            <p>
                <strong>Operations</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>The club will operate within Uganda.</li>
                    <li>
                        The club may elect to expand its operations outside of Uganda or change its area of operation.
                    </li>
                    <li>
                        Any decision to change or expand the area of operation must be supported by a Board Resolution
                        from Azima Investment Club and Pay 24 Financial Services Limited.
                    </li>
                    <li>
                        The fund will be jointly managed by the Board and the management team of Pay 24 Financial
                        Services Limited.
                    </li>
                </ol>
            </div>

            {/* membership */}
            <p>
                <strong>Membership</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Membership includes all original subscribers to this constitution who apply and get approval on
                        the Azima Investment Club website: www.azima.club
                    </li>
                    <li>Non-original subscribers can apply for membership on the Azima Investment Club website.</li>
                    <li>
                        Applicants will receive a notification from Azima Investment Club about the status of their
                        application.
                    </li>
                    <li>
                        Pay 24 Financial Services Limited, who is the fund manager, must approve all membership
                        applications.
                    </li>
                    <li>
                        Membership applications must include the applicant's name, mobile number, alternate contact
                        information, next of kin contact information, government-issued identity documents, and other
                        details specified on the club website.
                    </li>
                    <li>Membership ends when a member:</li>
                </ol>
            </div>

            <div style={{ marginLeft: 40 }}>
                <ol>
                    <li>passes away, or </li>
                    <li>
                        is removed by Pay 24 Financial Services Limited at an Annual General or Extraordinary General
                        meeting, or
                    </li>
                    <li>gives at least 7 (seven) business days' notice to leave the club.</li>
                    <li>
                        In such instances, they will get back their contributed money, plus any interest earned during
                        their investment period, minus fund management costs & processing fees.
                    </li>
                </ol>
            </div>

            {/* management */}
            <p>
                <strong>Management</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Pay 24 Financial Services Limited’s Board of Directors will manage the investment club and
                        handle daily operations through its management team.
                    </li>
                    <li>
                        The Board of Directors must act in the best interest of the club members when managing the
                        investment fund.
                    </li>
                </ol>
            </div>

            {/* power and duties */}
            <p>
                <strong>Board of Directors Powers and Duties:</strong>{" "}
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The Board has full control of the club's affairs, only limited by the objectives of the club.
                    </li>
                    <li>The Board can:</li>
                </ol>
            </div>

            <div style={{ marginLeft: 25 }}>
                <ol type="i">
                    <li>
                        Change the constitution for the club's proper functioning, with approval from members (such
                        member approval is provided by their continued membership for a period of one month from the
                        date the change takes place)
                    </li>
                    <li>Use documents and instruments necessary for managing the club's business.</li>
                    <li>
                        Make rules for meetings and club matters, as long as they don't conflict with the constitution.
                    </li>
                    <li>Hire professionals to run the club's businesses and use expert services when needed.</li>
                    <li>
                        Manage funds to help members get a return on their investment by financing the loans offered of
                        Azima Instant Loans.
                    </li>
                    <li>Keep a register of club members.</li>
                    <li>Appoint officers or employees to ensure the club’s efficient functioning.</li>
                </ol>
            </div>

            {/* GENERAL MEETING  */}
            <p>
                <strong>General Meeting:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The Annual General Meeting (AGM) has the highest authority, where every member can attend and
                        vote.
                    </li>
                    <li>
                        The AGM happens yearly, at least 3 (three) months before the end of the club’s financial year,
                        and covers matters including:
                    </li>
                    <ol type="i" style={{ marginLeft: 20 }}>
                        <li>considering amendments,</li>
                        <li>reviewing reports, balance sheets, and audit reports,</li>
                        <li>appointing an auditor for the next year,</li>
                        <li>and transact and other business matters.</li>
                    </ol>
                    <li>Members must vote in person; no proxies are allowed.</li>
                    <li>
                        A 14-day (fourteen-day) notice with a list of business items will be published on Azima
                        Investment Club's website before a general meeting.
                    </li>
                    <li>The AGM can be called with shorter notice if the Board approves a resolution for it.</li>
                </ol>
            </div>

            {/* QUORUM OF THE ANNUAL GENERAL MEETING   */}
            <p>
                <strong>Quorum at the AGM:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Three-quarters of members present (in-person or online) and qualified to vote make a quorum for
                        AGM or Extraordinary General Meetings (EGM).
                    </li>
                    <li>
                        If no quorum is present within 30 (thirty) minutes of the scheduled start, the meeting will be
                        adjourned for 30 (thirty) minutes. Where there is still no quorum:
                    </li>
                    <ol type="i" style={{ marginLeft: 20 }}>
                        <li>it shall be dissolved if the meeting was convened at the request of the members,</li>
                        <li>otherwise, those present will make a quorum.</li>
                    </ol>
                    <li>
                        The Board's Chairperson leads all meetings, or another board member appointed by the Chairperson
                        if the Chairperson is absent.
                    </li>
                </ol>
            </div>

            {/* VOTING */}
            <p>
                <strong>Voting:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Each qualified member present (an individual present in-person, or a corporation or legal entity
                        represented by one designated person) has one vote.
                    </li>
                    <li>Only fully paid-up members can vote at the AGM; other members may only attend.</li>
                    <li>Voting is by raising hands (in a physical meeting) or using an online platform feature.</li>
                    <li>Members can choose a different voting method by a simple majority resolution.</li>
                </ol>
            </div>

            {/* FUNDS */}
            <p>
                <strong>Funds:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The club's funds come from members' contributions and the interest on loans, minus fees & costs
                        (as described under Clauses 3.2 and 3.3).
                    </li>
                    <li>Unused funds are deposited in a bank chosen by the fund managers.</li>
                </ol>
            </div>

            {/* RECEIPT AND WITHDRAWAL OF FUNDS */}
            <p>
                <strong>Receipt and Withdrawal of Funds:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Members receive an acknowledgment for their contribution after depositing it. Deposit history
                        will be made available on the Azima Investment Club website.
                    </li>
                    <li>
                        Withdrawing members get their contribution and interest, minus management fees, as described
                        under Clause 5.6.4.
                    </li>
                </ol>
            </div>

            {/* LIEN */}
            <p>
                <strong>Lien:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>Members have the first claim on the money (capital) they contribute to the investment fund.</li>
                </ol>
            </div>

            {/* DEPOSITS AND INTEREST EARNED */}
            <p>
                <strong>Deposits and Interest Earned:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The club receives deposits from members when they're willing and able. The deposits must only be
                        made using the options available on the Azima Investment Club website.
                    </li>
                    <li>
                        Members earn 18% Annual interest on their deposits based on the amount contributed. This
                        interest is allocated every 24 hours at 0.049% based on the amount on the members account.
                        Deposits are capped at 1,000,000,000 UGX (One Billion Ugandan Shillings)
                    </li>
                </ol>
            </div>

            {/* ACCOUNTS  */}
            <p>
                <strong>Accounts:</strong>{" "}
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The Board prepares and maintains financial records, which will be available for members to
                        access by appointment or online.
                    </li>
                    <li>The Board creates financial statements for each year and presents them at the AGM.</li>
                    <li>The auditor's report is also presented at the AGM.</li>
                    <li>
                        The club's financial year runs from a 1<sup>st</sup> July to 30
                        <sup>th</sup>
                        June
                    </li>
                </ol>
            </div>

            {/* AUDITORS  */}
            <p>
                <strong>Auditors:</strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The Board ensures the club's accounts are audited within three months after each financial year
                        by a qualified auditor.
                    </li>
                </ol>
            </div>

            {/* DISPUTES */}
            <p>
                <strong>Disputes:</strong>{" "}
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        Disputes between the club, and its members or representatives will be settled by meeting with
                        the fund management.
                    </li>
                    <li>
                        In the event of a failure to reach an amicable settlement, the dispute will be referred to an
                        arbitrator appointed by the International Centre for Arbitration and Mediation, Kampala (ICAMEK)
                        and the decision of the arbitrator shall be final and binding on both Parties.
                    </li>
                </ol>
            </div>

            {/* DISSOLUTION  */}
            <p ref={dissolutionRef}>
                <strong>Dissolution: </strong>
            </p>
            <div style={{ marginLeft: 20 }}>
                <ol>
                    <li>
                        The investment club can be dissolved if Pay 24 Financial Services Limited becomes financially
                        insolvent, undergoes a merger or acquisition with another entity, or if the club members pass a
                        special resolution to end the club.
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default TermsCondition;
