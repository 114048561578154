import { Modal, Button, Tooltip } from "antd";
import styles from "./TermsConditionModal.module.scss";
import { useState } from "react";
import TermsCondition from "../TermsCondition/TermsCondition";

const TermsConditionModal = ({ showTermsModal, setShowTermsModal, setDisableRegisterButton }) => {
    const [buttonDisabled, setButtonDisabled] = useState(true);

    return (
        <Modal
            title={<h3 className={styles.modal_head}> Azima Club Constitution </h3>}
            open={showTermsModal}
            onCancel={() => setShowTermsModal(false)}
            closable={false}
            maskClosable={false}
            keyboard={false}
            footer={[
                <Tooltip title="Please read the whole terms & conditions to proceed.">
                    <Button
                        key="back"
                        disabled={buttonDisabled}
                        type="primary"
                        onClick={() => {
                            setShowTermsModal(false);
                            setDisableRegisterButton(false);
                        }}
                    >
                        I accept
                    </Button>
                </Tooltip>,
            ]}
            width={830}
            style={{ top: 30 }}
            centered
        >
            <TermsCondition page={false} setButtonDisabled={setButtonDisabled} />
        </Modal>
    );
};

export default TermsConditionModal;
