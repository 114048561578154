import { createContext, useState, useEffect } from 'react';
import { Spin } from 'antd';
import axios from '../Config/axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/users/authenticate', {
                    withCredentials: true,
                });
                setIsAuthenticated(true);
                setUser(response.data.user);
            } catch (error) {
                setIsAuthenticated(false);
                setUser(null);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            {loading ? (
                <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
                    <Spin size='large' />
                </div>
            ) : (
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated }}>{children}</AuthContext.Provider>
            )}
        </>
    );
};
