import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

// importing styles
import styles from "./Login.module.scss";

// importing components
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";

// importing icons
import { FaLock } from "react-icons/fa";

// country phone input imports
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

// importing axios
import axios from "../../Config/axios";

// importing contexts
import { AuthContext } from "../../Contexts/AuthContext";

const LoginPage = () => {
    let navigate = useNavigate();
    const { setUser, setIsAuthenticated } = useContext(AuthContext);

    const [loginLoading, setLoginLoading] = useState(false);

    const getFlag = (short) => {
        const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
        if (typeof data === "string") {
            return data;
        }
        return data.default;
    };

    const LoginHandler = async (values) => {
        // if no phone number present, throw error
        if (!values.phone.phone) {
            message.error("Phone number is required!");
            return;
        }

        if (values.phone.phone === "") {
            message.error("Phone number is required!");
            return;
        }

        const payload = {
            phone_number: `${values.phone.code}${values.phone.phone}`,
            password: values.password,
        };

        try {
            setLoginLoading(true);
            const response = await axios.post("/users/login", payload);
            localStorage.setItem("device_id", response.data.access_token);
            setIsAuthenticated(true);
            setUser(response.data.user);
            message.success("Successfully logged in!");
            navigate("/member/dashboard", { replace: true });
        } catch (error) {
            message.error("Invalid phone number or password");
        } finally {
            setLoginLoading(false);
        }
    };

    return (
        <div className={styles.login_page_container}>
            <Helmet>
                <title>Login - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.login_content}>
                    <Row gutter={20}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <div className={styles.login_left}>
                                <Link to="/" className={styles.logo_container}>
                                    <img
                                        className={styles.logo}
                                        src="https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png"
                                        alt="logo"
                                    />
                                </Link>
                                <div className={styles.login_form}>
                                    <h5 className={styles.form_title}>Log in to continue to Azima Club.</h5>
                                    <ConfigProvider
                                        locale={en}
                                        areaMapper={(area) => {
                                            return {
                                                ...area,
                                                emoji: (
                                                    <img
                                                        alt="flag"
                                                        style={{
                                                            width: 18,
                                                            height: 18,
                                                            verticalAlign: "sub",
                                                        }}
                                                        src={getFlag(area.short)}
                                                    />
                                                ),
                                            };
                                        }}
                                    >
                                        <Form
                                            initialValues={{
                                                remember: true,
                                                phone: {
                                                    short: "UG",
                                                },
                                            }}
                                            onFinish={LoginHandler}
                                            scrollToFirstError
                                            layout="vertical"
                                            requiredMark={false}
                                        >
                                            <Form.Item
                                                name="phone"
                                                label="Phone Number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter your phone number!",
                                                    },
                                                ]}
                                            >
                                                <CountryPhoneInput
                                                    type="number"
                                                    inputMode={"numeric"}
                                                    maxLength={9}
                                                    style={{ padding: 0, height: 32 }}
                                                    onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                                                    inline
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="password"
                                                label="PIN"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                    {
                                                        min: 4,
                                                        message: "Password must be at least 4 characters long.",
                                                    },
                                                    {
                                                        max: 4,
                                                        message: "Password can't be more than 4 characters long.",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder="1234" type="number" inputMode="numeric" />
                                            </Form.Item>

                                            <Form.Item name="remember" valuePropName="checked">
                                                <Checkbox>Remember me</Checkbox>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button block loading={loginLoading} type="primary" htmlType="submit">
                                                    Login
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </ConfigProvider>
                                    <div className={styles.bottom_links}>
                                        <Link to="/forgot-password">
                                            <FaLock color="#9ca8b3" />
                                            <span style={{ marginLeft: 5 }}>Forgot Password?</span>
                                        </Link>

                                        <Link to="/register">
                                            <span>Create an account</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={0}>
                            <img
                                src="https://images.pexels.com/photos/3663314/pexels-photo-3663314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="right_image"
                                className={styles.login_right_img}
                            />
                        </Col>
                    </Row>
                </div>
            </div>{" "}
        </div>
    );
};

export default LoginPage;
