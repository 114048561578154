import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

// importing styles
import styles from "./Register.module.scss";

// importing components
import { Button, Col, DatePicker, Form, Input, Row, Select, message } from "antd";

// country phone input imports
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import axios from "axios";
import en from "world_countries_lists/data/countries/en/world.json";
import TermsConditionModal from "../../Components/TermsConditionModal/TermsConditionModal";
import moment from "../../Config/moment";

const Register = () => {
    const [sendOTPLoading, setSendOTPLoading] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [disableRegisterButton, setDisableRegisterButton] = useState(true);
    const navigate = useNavigate();

    // show terms modal to proceed next
    useEffect(() => {
        setTimeout(() => {
            setShowTermsModal(true);
        }, 500);
    }, []);

    const getFlag = (short) => {
        const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
        if (typeof data === "string") {
            return data;
        }
        return data.default;
    };

    const sendOTPAndSetStates = async (values) => {
        // if no phone number present, throw error
        if (!values.phone.phone) {
            message.error("Phone number is required!");
            return;
        }

        if (values.phone.phone === "") {
            message.error("Phone number is required!");
            return;
        }

        // if no next of kin phone number present, throw error
        if (!values.next_of_kin.phone) {
            message.error("Next of kin phone number is required!");
            return;
        }

        if (values.next_of_kin.phone === "") {
            message.error("Next of kin phone number is required!");
            return;
        }

        // save unformatted values in localStorage
        localStorage.setItem("registerStates", JSON.stringify(values));

        const phone_number = `${values.phone.code}${values.phone.phone}`;
        const next_of_kin = `${values.next_of_kin.code}${values.next_of_kin.phone}`;

        try {
            setSendOTPLoading(true);
            const res = await axios.post("https://api.azima.info/api/otp/send?check=true", { phone_number });

            // set hashed value from response
            values.hashed_otp = res.data.hashed_otp;
        } catch (error) {
            if (error.response.status === 406) {
                message.error("Account already exists, please sign-in with your phone number and PIN");
            } else {
                message.error("Can't send OTP right now!");
            }
            return;
        } finally {
            setSendOTPLoading(false);
        }

        values.date_of_birth = values["date_of_birth"].format("YYYY-MM-DD");
        values.phone_number = phone_number;
        values.next_of_kin = next_of_kin;

        // navigate to /otp with states
        navigate("/register/otp", { state: { registerStates: values } });
    };

    return (
        <div className={styles.register_page_container}>
            <Helmet>
                <title>Register - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.register_content}>
                    <Row gutter={20}>
                        <Col lg={24}>
                            <div className={styles.register_left}>
                                <Link to="/" className={styles.logo_container}>
                                    <img
                                        className={styles.logo}
                                        src="https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png"
                                        alt="logo"
                                    />
                                </Link>
                                <div className={styles.register_form}>
                                    <h5 className={styles.form_title}>Register with Azima Club</h5>

                                    <span className={styles.reg_already}>
                                        If you're already registered with <a href="/">Azima App</a> no need to re-register here. Simply use
                                        your existing Phone Number and Pin to <Link to={"/login"}>Login</Link>{" "}
                                    </span>

                                    <ConfigProvider
                                        locale={en}
                                        areaMapper={(area) => {
                                            return {
                                                ...area,
                                                emoji: (
                                                    <img
                                                        alt="flag"
                                                        style={{
                                                            width: 18,
                                                            height: 18,
                                                            verticalAlign: "sub",
                                                        }}
                                                        src={getFlag(area.short)}
                                                    />
                                                ),
                                            };
                                        }}
                                    >
                                        <Form
                                            initialValues={{
                                                accept: true,
                                                phone: {
                                                    short: "UG",
                                                },
                                                next_of_kin: {
                                                    short: "UG",
                                                },
                                                ...JSON.parse(localStorage.getItem("registerStates")),
                                                date_of_birth: localStorage.getItem("registerStates")
                                                    ? moment(JSON.parse(localStorage.getItem("registerStates")).date_of_birth)
                                                    : null,
                                            }}
                                            onFinish={sendOTPAndSetStates}
                                            scrollToFirstError
                                            layout="vertical"
                                            requiredMark={false}
                                        >
                                            <Row gutter={[20, 0]}>
                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="first_name"
                                                        label="First Name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your first name!",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="John" />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="last_name"
                                                        label="Last Name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your last name!",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Doe" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[
                                                            {
                                                                type: "email",
                                                                message: "The input is not valid Email!",
                                                            },
                                                            {
                                                                required: true,
                                                                message: "Please input your Email!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="yourname@yourdomain.com" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Phone Number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your phone number!",
                                                            },
                                                        ]}
                                                    >
                                                        <CountryPhoneInput
                                                            style={{ padding: 0, height: 32 }}
                                                            inputMode={"numeric"}
                                                            type="number"
                                                            inline
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="district"
                                                        label="District"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your district name!",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Wakiso" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="town"
                                                        label="Town"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your town name!",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Kampala" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="date_of_birth"
                                                        label="Date of Birth"
                                                        rules={[
                                                            {
                                                                type: "object",
                                                                required: true,
                                                                message: "Please select date of birth",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <DatePicker
                                                            allowClear={false}
                                                            style={{ width: "100%" }}
                                                            placeholder="1990-08-25"
                                                            format="YYYY-MM-DD"
                                                            onFocus={(e) => e.target.blur()}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="sex"
                                                        label="Gender"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select your gender!",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Select placeholder="Male">
                                                            <Select.Option value="Male">Male</Select.Option>
                                                            <Select.Option value="Female">Female</Select.Option>
                                                            <Select.Option value="Other">Other</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="password"
                                                        label="4-Digit PIN"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your password!",
                                                            },
                                                            {
                                                                min: 4,
                                                                message: "PIN must be at least 4 characters long.",
                                                            },
                                                            {
                                                                max: 4,
                                                                message: "PIN can't be more than 4 characters long.",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="1234" inputMode="numeric" type="number" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="confirm"
                                                        label="Confirm 4-Digit PIN"
                                                        dependencies={["password"]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please confirm your password!",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue("password") === value) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error("The two passwords that you entered do not match!")
                                                                    );
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input placeholder="1234" inputMode="numeric" type="number" />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item name="nin" label="National ID Number">
                                                        <Input placeholder="CM/123456789" showCount maxLength={14} />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        name="next_of_kin"
                                                        label="Next of Kin's Number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input next of kin phone number!",
                                                            },
                                                        ]}
                                                    >
                                                        <CountryPhoneInput
                                                            style={{ padding: 0, height: 32 }}
                                                            inline
                                                            type="number"
                                                            inputMode={"numeric"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item>
                                                <div>
                                                    By proceeding you agree with our
                                                    <span onClick={() => setShowTermsModal(true)} className={styles.terms_btn}>
                                                        Constitution
                                                    </span>
                                                </div>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button
                                                    block
                                                    disabled={disableRegisterButton}
                                                    loading={sendOTPLoading}
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Next
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </ConfigProvider>
                                    <div className={styles.bottom_links}>
                                        <Link to="/login">
                                            <span>Already have an account?</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* open terms and condition modal component */}
            {showTermsModal && (
                <TermsConditionModal
                    setDisableRegisterButton={setDisableRegisterButton}
                    showTermsModal={showTermsModal}
                    setShowTermsModal={setShowTermsModal}
                />
            )}
        </div>
    );
};

export default Register;
