import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// importing components
import { Drawer } from 'antd';
import TermsCondition from '../../Components/TermsCondition/TermsCondition';
import PrimarySidebar from '../../Components/PrimarySidebar/PrimarySidebar';

// importing contexts
import { AuthContext } from '../../Contexts/AuthContext';

// importing styles
import styles from "./TermsAndCondition.module.scss";

// importing icons
import { BiMenuAltRight } from "react-icons/bi";

const TermsAndCondition = () => {
    const { isAuthenticated } = useContext(AuthContext)
    const [drawerVisible, setDrawerVisible] = useState(false);
    return (
        <div className={styles.terms_and_condition}>
            <Helmet>
                <title>Terms & Conditions - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.content_top}>
                    <div className={styles.logo_container}>
                        <Link to='/'>
                            <img className={styles.logo} src='https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png' alt='logo' />
                        </Link>
                    </div>
                    <div className={styles.mobile_hamburger} onClick={() => setDrawerVisible(true)}>
                        <BiMenuAltRight color='var(--color-dark)' size={30} />
                    </div>
                    <div className={styles.right_links}>
                        {isAuthenticated ? (
                            <Link to='/member/dashboard' className={styles.login}>
                                Go To Dashboard
                            </Link>
                        ) : (
                            <>
                                <Link to='/register' className={styles.register}>
                                    Register
                                </Link>
                                <Link to='/login' className={styles.login}>
                                    Login
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: 25 }}>
                <div className="container">
                    <div className={styles.content_main}>
                        <TermsCondition page setButtonDisabled={false} />
                    </div>
                </div>
            </div>

            {/* show drawer in mobile */}
            <Drawer
                placement="right"
                closable={false}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                key="right"
                width={270}
            >
                <PrimarySidebar />
            </Drawer>
        </div>
    );
};

export default TermsAndCondition;
