import styles from './NotFound.module.scss';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className={styles.notFound}>
            <div className={styles.notFound_content}>
                <div className={styles.notFound_texts}>
                    <div className={styles.notFound_404}>404 </div>
                    <Divider type='vertical' className={styles.divider} />
                    <div className={styles.notFound_helper}>Page Not Found!</div>
                </div>

                <Link to='/' className={styles.get_started_btn}>
                    Go Back Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
