import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';

const MemberRoutes = () => {
    let location = useLocation();
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
};

export default MemberRoutes;
