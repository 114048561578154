import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";

// importing styles
import styles from "./ForgotPassword.module.scss";

// importing components
import { Row, Col, Button, Form, Input, message } from "antd";

// importing icons
import { FaLock } from "react-icons/fa";

// country phone input imports
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [sendOTPLoading, setSendOTPLoading] = useState(false);
    const [resetPassLoading, setResetPassLoading] = useState(false);
    const [state, setState] = useState({
        phone_number: "",
        user_given_otp: "",
        hashed_otp: "",
        new_password: "",
    });

    const SendOTP = async (values) => {
        if (!values.phone.phone) {
            message.error("Phone number is required!");
            return;
        }

        if (values.phone.phone === "") {
            message.error("Phone number is required!");
            return;
        }

        const phone_number = `${values.phone.code}${values.phone.phone}`;

        try {
            setSendOTPLoading(true);
            const res = await axios.post("https://api.azima.info/api/otp/send", { phone_number });

            // set hashed value from response
            values.hashed_otp = res.data.hashed_otp;
        } catch (error) {
            message.error("Can't send OTP right now!");
            return;
        } finally {
            setSendOTPLoading(false);
        }

        setState((prev) => ({ ...prev, phone_number, hashed_otp: values.hashed_otp }));
        setStep(2);
    };

    const VerifyOTP = (values) => {
        setState((prev) => ({ ...prev, user_given_otp: values.otp }));
        setStep(3);
    };

    const ResetPassword = async (values) => {
        let payload = {
            ...state,
            new_password: values.new_password,
        };
        try {
            setResetPassLoading(true);
            console.log(payload);
            await axios.patch("https://api.azima.info/api/users/reset-pin", payload);
            setStep(4);
        } catch (error) {
            message.error(error.response.data.msg);
        } finally {
            setResetPassLoading(false);
        }
    };

    const getFlag = (short) => {
        const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
        if (typeof data === "string") {
            return data;
        }
        return data.default;
    };

    return (
        <div className={styles.forgot_container}>
            <Helmet>
                <title>Forgot Password - Azima Club</title>
            </Helmet>
            <div className="container">
                <div className={styles.forgot_content}>
                    <Row gutter={20}>
                        <Col lg={12} xs={24}>
                            <div className={styles.forgot_left}>
                                <Link to="/" className={styles.logo_container}>
                                    <img className={styles.logo} src="https://i.ibb.co/9WwS3mR/icon-removebg-preview.png" alt="logo" />
                                </Link>
                                <div className={styles.forgot_form}>
                                    <h5 className={styles.form_title}>Forgot Password</h5>
                                    {step === 1 && (
                                        <>
                                            <div className={styles.form_content_title}>
                                                Forgot your password? Let us help. Please enter your phone number and follow later steps.
                                            </div>
                                            <ConfigProvider
                                                locale={en}
                                                areaMapper={(area) => {
                                                    return {
                                                        ...area,
                                                        emoji: (
                                                            <img
                                                                alt="flag"
                                                                style={{ width: 18, height: 18, verticalAlign: "sub" }}
                                                                src={getFlag(area.short)}
                                                            />
                                                        ),
                                                    };
                                                }}
                                            >
                                                <Form
                                                    onFinish={SendOTP}
                                                    initialValues={{
                                                        phone: {
                                                            short: "UG",
                                                        },
                                                    }}
                                                    scrollToFirstError
                                                    layout="vertical"
                                                    requiredMark={false}
                                                >
                                                    <Form.Item
                                                        name="phone"
                                                        label="Phone Number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your phone number!",
                                                            },
                                                        ]}
                                                    >
                                                        <CountryPhoneInput
                                                            style={{ padding: 0, height: 32 }}
                                                            type="number"
                                                            inputMode="numeric"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <Button block loading={sendOTPLoading} type="primary" htmlType="submit">
                                                            Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </ConfigProvider>
                                        </>
                                    )}
                                </div>
                                {step === 2 && (
                                    <>
                                        <div className={styles.form_content_title}>
                                            To verify your phone number we have sent a 4 digit OTP to {state.phone_number}. Please enter the
                                            4 digit code and click on the Verify button below to proceed!
                                        </div>
                                        <Form onFinish={VerifyOTP} scrollToFirstError layout="vertical" requiredMark={false}>
                                            <Form.Item
                                                name="otp"
                                                label="OTP"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input 4 digit OTP!",
                                                    },
                                                    {
                                                        min: 4,
                                                        message: "OTP is 4 digit long.",
                                                    },
                                                    {
                                                        max: 4,
                                                        message: "OTP is 4 digit only.",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button block type="primary" htmlType="submit">
                                                    Continue
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </>
                                )}
                                {step === 3 && (
                                    <>
                                        <div className={styles.form_content_title}>Enter and confirm your new password below. </div>
                                        <Form onFinish={ResetPassword} scrollToFirstError layout="vertical" requiredMark={false}>
                                            <Form.Item
                                                name="new_password"
                                                label="New Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                    {
                                                        min: 4,
                                                        message: "Password must be at least 4 characters long.",
                                                    },
                                                    {
                                                        max: 4,
                                                        message: "Password can't be more than 4 characters long.",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password />
                                            </Form.Item>

                                            <Form.Item
                                                name="confirm_new_password"
                                                label="Confirm Password"
                                                dependencies={["new_password"]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please confirm your password!",
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue("new_password") === value) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                new Error("The two passwords that you entered do not match!")
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button block loading={resetPassLoading} type="primary" htmlType="submit">
                                                    Verify & Reset
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </>
                                )}

                                {step === 4 && (
                                    <div className={styles.forgotSuccess}>
                                        <div className={styles.animatedTick}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <circle
                                                    className={`${styles.path} ${styles.circle}`}
                                                    fill="none"
                                                    stroke="#73AF55"
                                                    strokeWidth="6"
                                                    strokeMiterlimit="10"
                                                    cx="65.1"
                                                    cy="65.1"
                                                    r="62.1"
                                                />
                                                <polyline
                                                    className={`${styles.path} ${styles.check}`}
                                                    fill="none"
                                                    stroke="#73AF55"
                                                    strokeWidth="6"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                                />
                                            </svg>
                                        </div>
                                        <h2>Password Reset successful. Login with your new credential.</h2>
                                        <Link to="/login">
                                            <Button size="large" block type="primary">
                                                Login
                                            </Button>
                                        </Link>
                                    </div>
                                )}

                                <div className={styles.bottom_links}>
                                    <Link to="/login">
                                        <FaLock color="#9ca8b3" />
                                        <span style={{ marginLeft: 5 }}>Log In</span>
                                    </Link>

                                    <Link to="/register">
                                        <span>Create an account</span>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} xs={0}>
                            <img
                                src="https://images.pexels.com/photos/3663314/pexels-photo-3663314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="right_image"
                                className={styles.forgot_right_img}
                            />
                        </Col>
                    </Row>
                </div>
            </div>{" "}
        </div>
    );
};

export default ForgotPassword;
