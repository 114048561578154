import { useContext, useEffect, useState } from "react";
import axios from "../../Config/axios";

import momentRaw from "moment";
import moment from "../../Config/moment";

// importing styles
import styles from "./MemberDashboard.module.scss";

// importing components
import {
  Button,
  Col,
  Divider,
  Input,
  Pagination,
  Radio,
  Row,
  Spin,
  Statistic,
  Tag,
  message,
} from "antd";
import DashboardLayout from "../../Components/DashboardLayout/DashboardLayout";

// importing icons
import { AiFillMinusCircle, AiOutlineUser } from "react-icons/ai";
import {
  BsArrowRight,
  BsFillBagCheckFill,
  BsFillPlusCircleFill,
} from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";
import { MdSend } from "react-icons/md";

//importing context
import NoData from "../../Components/NoData/NoData";
import CurrentDeposit from "../../Components/Transaction/CurrentDeposit";
import CurrentWithdraw from "../../Components/Transaction/CurrentWithdraw";
import { AuthContext } from "../../Contexts/AuthContext";
import { calculatePercentage, formattedAmount } from "../../utils/utils";

const MemberDashboard = () => {
  const { user } = useContext(AuthContext);

  const [globalData, setGlobalData] = useState({});
  const [selector, setSelector] = useState("deposit");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const [currentDeposit, setCurrentDeposit] = useState(null);
  const [currentWithdraw, setCurrentWithdraw] = useState(null);

  // user transactions states
  const [selectedValue, setSelectedValue] = useState("deposit");
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [spin, setSpin] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [deadline, setDeadline] = useState(
    moment() > momentRaw().hour(9)
      ? momentRaw().hour(9).add(1, "day")
      : momentRaw().hour(9)
  );

  const [page, setPage] = useState(1);

  const [count, setCount] = useState({
    deposit: 0,
    withdraw: 0,
  });

  // sort by date updated
  const sortedTransaction = (arr) => {
    return arr.sort(
      (a, b) => moment(b.updated_at).unix() - moment(a.updated_at).unix()
    );
  };

  // get transactions
  useEffect(() => {
    let d = new Date();
    d.setHours(9, 0, 0, 0);
    let today = d.setDate(d.getDate() + 0);
    let tomorrow = d.setDate(d.getDate() + 1);
    if (new Date() > today) {
      setDeadline(tomorrow);
    } else {
      setDeadline(today);
    }

    if (selectedValue === "deposit") {
      (async () => {
        setSpin(true);
        const res = await axios.get("/transactions/deposits", {
          withCredentials: true,
          params: {
            page: page,
            limit: 15,
          },
        });
        setCount({
          deposit: res.data.count,
          withdraw: 0,
        });
        setRecentTransactions(sortedTransaction(res.data.results));
        setTransactionLoading(false);
        setSpin(false);
      })();
    }
    if (selectedValue === "withdraw") {
      (async () => {
        setSpin(true);
        const res = await axios.get("/transactions/withdraws", {
          withCredentials: true,
          params: {
            page: page,
            limit: 15,
          },
        });
        setCount({
          deposit: 0,
          withdraw: res.data.count,
        });
        setRecentTransactions(sortedTransaction(res.data.results));
        setSpin(false);
        setTransactionLoading(false);
      })();
    }
  }, [selectedValue, refresh, page]);

  // get global data
  useEffect(() => {
    (async () => {
      const res = await axios.get("/fundamentals/stat", {
        withCredentials: true,
      });
      setGlobalData(res.data);
    })();
  }, []);

  // cards info
  const cards = [
    {
      icon: <IoCubeOutline size={20} color="#fff" />,
      title: "Annual Earning",
      description: "Interest per month",
      amount: "18%",
      description_amount: "1.5% (30 days)",
    },
    {
      icon: <BsFillBagCheckFill size={20} color="#fff" />,
      title: "Total Member Deposits",
      description: `Yesterday's Deposits`,
      amount: globalData.deposit_amount ?? "...",
      //TODO: user server data
      description_amount: globalData.yesterday_deposit
        ? `1,048,855 UGX`
        : `0 UGX`,
    },
    {
      icon: <AiOutlineUser size={20} color="#fff" />,
      title: "Total Members",
      description: "Members Last Month",
      amount: globalData.user_count ?? "...",
      description_amount: globalData.user_count_last_two_months ?? "...",
    },
    {
      icon: <FaMoneyCheckAlt size={20} color="#fff" />,
      title: "Your Balance",
      description: "Available To Withdraw",
      amount: user.balance,
      description_amount: `UGX ${user.interest_amount}`,
    },
  ];

  const user_statistics = [
    {
      title: "Your total contribution",
      amount: user.deposit_amount,
    },
    {
      title: "Your total reward",
      amount: user.interest_amount,
    },
    {
      title: "Your next reward",
      amount: calculatePercentage(user.deposit_amount, 0.0484),
    },
    {
      title: "What you will have in 30 days",
      amount:
        user.deposit_amount +
        calculatePercentage(user.deposit_amount, 0.0484) * 30,
    },
    {
      title: "What you will have in 1 year",
      amount:
        user.deposit_amount +
        calculatePercentage(user.deposit_amount, 0.0484) * 365,
    },
    {
      title: "What you will have in 2 years",
      amount:
        user.deposit_amount +
        calculatePercentage(user.deposit_amount, 0.0484) * 365 * 2,
    },
    {
      title: "What you will have in 5 years",
      amount:
        user.deposit_amount +
        calculatePercentage(user.deposit_amount, 0.0484) * 365 * 5,
    },
    {
      title: "What you will have in 10 years",
      amount:
        user.deposit_amount +
        calculatePercentage(user.deposit_amount, 0.0484) * 365 * 10,
    },
  ];

  const depositHandler = () => {
    if (amount < 1000) {
      message.error("Minimum deposit amount is 1000 UGX");
      return;
    }

    setLoading(true);

    let payload = {
      amount: amount,
    };

    axios
      .post("/transactions/make-deposit", payload, { withCredentials: true })
      .then((response) => {
        setCurrentDeposit(response.data);
      })
      .catch((error) => {
        message.error(error.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
        setAmount("");
      });
  };

  const withdrawHandler = () => {
    if (amount < 1500) {
      message.error("Minimum withdraw amount is 1500 UGX");
      return;
    }

    if (amount > user.interest_amount - 1000) {
      message.error(
        `Your maximum withdrawal amount is ${user.interest_amount - 1000} UGX`
      );
      return;
    }

    setLoading(true);

    let payload = {
      amount: amount,
    };

    axios
      .post("/transactions/make-withdrawal", payload, { withCredentials: true })
      .then((response) => {
        setCurrentWithdraw(response.data);
      })
      .catch((error) => {
        message.error(error.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
        setAmount("");
      });
  };

  return (
    <DashboardLayout title="Dashboard">
      <Row gutter={20}>
        <Col lg={16} sm={16} xs={24}>
          <div className={styles.cards}>
            <Row gutter={[10, 10]}>
              {cards.map((card, i) => (
                <Col key={i} lg={6} sm={12} xs={24}>
                  <div className={styles.card}>
                    <div
                      style={{
                        backgroundColor:
                          i === 3 ? "var(--color-primary)" : null,
                      }}
                      className={styles.card_icon}
                    >
                      {card.icon}
                    </div>
                    <div
                      style={{ color: i === 3 ? "var(--color-primary)" : null }}
                      className={styles.card_title}
                    >
                      {card.title}
                    </div>
                    <div
                      style={{ color: i === 3 ? "var(--color-primary)" : null }}
                      className={styles.card_amount}
                    >
                      {formattedAmount(card.amount)}{" "}
                    </div>
                    <Divider style={{ margin: "5px 0", marginTop: "auto" }} />
                    <div className={styles.card_description}>
                      {card.description}
                    </div>
                    <div className={styles.card_description}>
                      {formattedAmount(card.description_amount)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Row>
            {/* mobile version of deposit withdraw */}
            <Col xs={24} sm={0}>
              <DashboardRightCard
                deadline={deadline}
                selector={selector}
                setSelector={setSelector}
                setAmount={setAmount}
                depositHandler={depositHandler}
                withdrawHandler={withdrawHandler}
                loading={loading}
                user_statistics={user_statistics}
              />
            </Col>
          </Row>
          <div className={styles.recent_transactions}>
            <div className={styles.recent_transactions_top}>
              <div className={styles.recent_transactions_top_title}>
                Your Transactions
              </div>
              <Radio.Group
                defaultValue="deposit"
                buttonStyle="solid"
                value={selectedValue}
                onChange={(e) => {
                  setPage(1);
                  setSelectedValue(e.target.value);
                }}
              >
                <Radio.Button value="deposit">Deposit</Radio.Button>
                <Radio.Button value="withdraw">Withdraw</Radio.Button>
              </Radio.Group>
            </div>
            {transactionLoading ? (
              <div
                style={{
                  minHeight: "30vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin spinning={transactionLoading} />
              </div>
            ) : (
              <>
                {recentTransactions.length > 0 ? (
                  <>
                    <Spin spinning={spin}>
                      <div className={styles.table_wrapper}>
                        {recentTransactions.map((transaction, i) => (
                          <div className={styles.table_item} key={i}>
                            {transaction.type === "deposit" ? (
                              <BsFillPlusCircleFill size={20} color="#1890ff" />
                            ) : (
                              <AiFillMinusCircle size={22} color="#ff4d4f" />
                            )}
                            <div className={styles.transaction_type}>
                              {transaction.user.phone_number}
                            </div>
                            <div className={styles.transaction_time}>
                              {moment(transaction.updated_at).format(
                                "DD MMM YYYY, LT"
                              )}
                            </div>
                            <div className={styles.transaction_amount}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  UGX {formattedAmount(transaction.amount)}
                                </span>
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    color: "var(--color-grey-dark-3)",
                                  }}
                                >
                                  TRX Fee: UGX{" "}
                                  {formattedAmount(transaction.trx_fee)}
                                </span>
                              </div>
                            </div>
                            <div className={styles.action_btn}>
                              <Tag
                                color={
                                  transaction.status === "pending"
                                    ? "var(--color-warning)"
                                    : transaction.status === "success"
                                    ? "var(--color-primary)"
                                    : "var(--color-red)"
                                }
                                style={{ textTransform: "uppercase" }}
                              >
                                {transaction.status}
                              </Tag>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Spin>
                    <div className="center_pagination">
                      <Pagination
                        total={
                          selectedValue === "deposit"
                            ? count.deposit
                            : count.withdraw
                        }
                        onChange={(page) => setPage(page)}
                        pageSize={15}
                        current={page}
                        showSizeChanger={false}
                        showLessItems
                        responsive
                      />
                    </div>
                  </>
                ) : (
                  <Spin spinning={spin}>
                    <NoData message="No Transaction Found!" />
                  </Spin>
                )}
              </>
            )}
          </div>
        </Col>

        {/* desktop version of deposit withdraw */}
        <Col lg={8} sm={8} xs={0}>
          <DashboardRightCard
            deadline={deadline}
            selector={selector}
            setSelector={setSelector}
            setAmount={setAmount}
            depositHandler={depositHandler}
            withdrawHandler={withdrawHandler}
            loading={loading}
            user_statistics={user_statistics}
          />
        </Col>
      </Row>

      {currentDeposit && (
        <CurrentDeposit
          deposit={currentDeposit}
          setCurrentDeposit={setCurrentDeposit}
          setRefresh={setRefresh}
        />
      )}

      {currentWithdraw && (
        <CurrentWithdraw
          withdraw={currentWithdraw}
          setCurrentWithdraw={setCurrentWithdraw}
          setRefresh={setRefresh}
        />
      )}
    </DashboardLayout>
  );
};

export default MemberDashboard;

const DashboardRightCard = ({
  deadline,
  selector,
  setSelector,
  setAmount,
  depositHandler,
  withdrawHandler,
  loading,
  user_statistics,
}) => {
  return (
    <div className={styles.card + " " + styles.action_card}>
      <div className={styles.countdown}>
        <div style={{ fontSize: 13 }}>Time for next reward</div>
        <BsArrowRight size={20} />
        <Statistic.Countdown value={deadline} valueStyle={{ fontSize: 18 }} />
        <div style={{ fontSize: 16, marginLeft: -5 }}>Left</div>
      </div>
      <div className={styles.deposit_withdraw_form}>
        <div className={styles.selector}>
          <div
            style={{
              backgroundColor:
                selector === "deposit" ? "var(--color-light)" : null,
              color: selector === "deposit" ? "var(--color-dark)" : null,
            }}
            onClick={() => setSelector("deposit")}
            className={styles.selector__left}
          >
            Deposit
          </div>
          <div
            style={{
              backgroundColor:
                selector === "withdraw" ? "var(--color-light)" : null,
              color: selector === "withdraw" ? "var(--color-dark)" : null,
            }}
            onClick={() => setSelector("withdraw")}
            className={styles.selector__right}
          >
            Withdraw
          </div>
        </div>
        <div className={styles.amount_form}>
          <Input
            placeholder="Enter value (e.g. 2000)"
            suffix="UGX"
            style={{ height: "48px", paddingRight: "6rem" }}
            type="number"
            onChange={(e) => setAmount(e.target.value)}
            inputMode={"numeric"}
          />
          <Button
            shape="circle"
            icon={<MdSend size={20} color={"var(--color-light)"} />}
            className={styles.trx_button}
            onClick={() =>
              selector === "deposit" ? depositHandler() : withdrawHandler()
            }
            loading={loading}
            disabled={loading}
          ></Button>
        </div>
        <span
          style={{
            fontSize: "1rem",
            color: "var(--color-grey-dark-3)",
            marginLeft: "1rem",
          }}
        >
          {selector === "deposit"
            ? "3% transaction fee applicable"
            : "1,000 UGX Transaction fee is applicable"}
        </span>
      </div>
      <div className={styles.personal_stat_wrapper}>
        <div className={styles.personal_stat_title}>Your statistics </div>

        {user_statistics.map((stat, i) => (
          <div key={i} className={styles.personal_stat}>
            <div className={styles.stat_title}>{stat.title} </div>
            <div className={styles.stat_amount}>
              UGX {formattedAmount(stat.amount)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
