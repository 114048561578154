import { useEffect } from 'react';
import axios from '../../Config/axios';

//importing components
import { Button, message, Modal, Result } from 'antd';


const CurrentWithdraw = ({ withdraw, setCurrentWithdraw, setRefresh }) => {

    useEffect(() => {
        async function verifyDeposit() {
            axios.get(`/transactions/withdraws/${withdraw.id}`, { withCredentials: true }).then(response => {
                console.log(response.data.status);
                if (response.data.status === 'success') {
                    message.success('Withdraw verified successfully!');
                    setCurrentWithdraw(null);
                    setRefresh(Math.random());
                }
            });
        }

        const intervalId = setInterval(() => {
            verifyDeposit()
        }, 1000 * 5) // in milliseconds
        return () => clearInterval(intervalId)
    }, [])



    return (
        <Modal
            open={true}
            centered={true}
            footer={null}
            closable={false}
        >
            <Result
                status="success"
                title="Transaction created successfully!"
                subTitle={"Withdrawal ID: " + withdraw.id}
                extra={[
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/live.svg" alt="" width={'50px'} />
                        <span style={{ color: 'var(--color-grey-dark-3)' }}>Your withdrawal is currently being processed. Balance will be disbursed automatically once it's processed successfully.</span>
                        <br />
                        <Button onClick={() => setCurrentWithdraw(null)}>Close</Button>
                    </div>
                ]}
            />
        </Modal>
    );
};

export default CurrentWithdraw;