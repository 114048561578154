import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';

const VerifiedMemberRoutes = () => {
    let location = useLocation();
    const { isAuthenticated, user } = useContext(AuthContext);

    if (!isAuthenticated) {
        return <Navigate to='/login' state={{ from: location }} replace />
    }

    if (isAuthenticated && user.user_data.status !== "verified") {
        return <Navigate to='/member/kyc' state={{ from: location }} replace />
    }

    if (isAuthenticated && user.user_data.status === "verified") {
        return <Outlet />
    }
};

export default VerifiedMemberRoutes;
