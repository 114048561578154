import { Helmet } from 'react-helmet-async';

// importing components
import LandingHero from '../../Components/LandingHero/LandingHero';
import LandingNav from '../../Components/LandingNav/LandingNav';

const LandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Home - Azima Club</title>
            </Helmet>
            <LandingNav />
            <LandingHero />
        </>
    );
};

export default LandingPage;
