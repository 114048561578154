import { useEffect } from 'react';
import axios from '../../Config/axios';

//importing components
import { Button, message, Modal, Result } from 'antd';


const CurrentDeposit = ({ deposit, setCurrentDeposit, setRefresh }) => {

    useEffect(() => {
        async function verifyDeposit() {
            axios.get(`/transactions/deposits/${deposit.id}`, { withCredentials: true }).then(response => {
                console.log(response.data.status);
                if (response.data.status === 'success') {
                    message.success('Deposit verified successfully!');
                    setCurrentDeposit(null);
                    setRefresh(Math.random());
                }
            });
        }

        const intervalId = setInterval(() => {
            verifyDeposit()
        }, 1000 * 5) // in milliseconds
        return () => clearInterval(intervalId)
    }, [])



    return (
        <Modal
            open={true}
            centered={true}
            footer={null}
            closable={false}
        >
            <Result
                status="success"
                title="Transaction created successfully!"
                subTitle={"Deposit ID: " + deposit.id}
                extra={[
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/live.svg" alt="" width={'50px'} />
                        <span style={{ color: 'var(--color-grey-dark-3)' }}>Your deposit is currently being processed. Balance will be updated automatically once it's processed and verified.</span>
                        <br />
                        <Button onClick={() => setCurrentDeposit(null)}>Close</Button>
                    </div>
                ]}
            />
        </Modal>
    );
};

export default CurrentDeposit;